.is-editing.card {
  border: 3px solid #00aeef;
}

.dragging.card {
  transform: rotateZ(-1deg);

  &:after {
    opacity: 1;
  }
}

.card {
  transition: all 0.2s ease;
  padding: 0.5rem;
  padding-bottom: 3.5rem;
  font-size: 1em;
  position: relative;
  min-height: 70px;
  border: 3px solid $black;
  box-shadow: none;
  border-radius: 0;
  outline: none;
  background: $white;

  &:after {
    transition: opacity 0.4s ease;
    opacity: 0;
    content: '';
    height: 4px;
    background: black;
    position: absolute;
    right: 10px;
    left: 10px;
    bottom: -7px;
  }

  .loader {
    transform: none;
  }
}

.card .description {
  padding-top: 2px;
  padding-left: 2px;
}

.card .editable {
  line-height: 1.5;
  padding-top: 2px;
  padding-left: 2px;
  outline: none;
  cursor: text;
}

.card button {
  padding: 0.3rem;
  text-align: center;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  background: #00aeef;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.card p {
  padding: 0;
  margin: 0;
  line-height: 1.4;
}

.card textarea {
  border: none;
  width: 100%;
  resize: both;
  box-sizing: border-box;
}

.card textarea:focus {
  outline: none;
}

.card .button-actions {
  margin-top: 1rem;
}

.card .thumbs {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #00aeef;
  color: white;
  width: 40px;
  text-align: center;
  border-right: 3px solid black;
  border-top: 3px solid black;
}

.thumbs .icon {
  padding-right: 0.4rem;
  /*margin-top: 4px;*/
}

.card .thumbs:disabled {
  background: rgba(0, 0, 0, 0.5);
}

.card .thumbs {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 60px;
  border-right: 3px solid black;
  border-top: 3px solid black;

  i {
    font-size: 1.1em;
    padding-right: 5px;
  }
}

.card .edit {
  display: flex;
  align-items: center;
  width: 78px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-top: 3px solid black;

  i {
    font-size: 1.1em;
    padding-right: 5px;
  }
}

.card .edit {
  border-left: 3px solid black;
}

.card .done {
  position: absolute;
  bottom: 0;
  left: 0;
  border-right: 3px solid black;
  border-top: 3px solid black;
  text-transform: uppercase;

  &:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.5);
  }
}

.card .remove {
  position: absolute;
  bottom: 0;
  right: 0;
  border-left: 3px solid black;
  border-top: 3px solid black;
  text-transform: uppercase;
  background: #ec008c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card .votes {
  bottom: 7px;
  position: absolute;
  left: 70px;
}

.dragging .edit {
  border: 3px solid black;
  bottom: -3px;
  right: -3px;
  width: 81px;
}

.dragging .thumbs {
  border: 3px solid black;
  bottom: -3px;
  left: -3px;
  width: 63px;
}

.is-editing .remove {
  width: 110px;
  border: 3px solid black;
  bottom: -3px;
  right: -3px;
}

.is-editing .done {
  width: 70px;
  border: 3px solid black;
  bottom: -3px;
  left: -3px;
}
