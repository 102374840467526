@import 'fonts';
@import '~bootstrap/scss/functions';
@import 'variables';
@import 'bootstrap';
@import 'scrollbar';
@import 'card';

body,
input,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
}

.text-center {
  text-align: center;
}

.home {
  @include make-container;

  header {
    margin-top: 1rem;
    height: 150px;

    @include media-breakpoint-down(xs) {
      text-align: center;
      margin-bottom: -3rem;
    }
  }
}

.home section {
  margin-top: 5rem;

  p {
    text-align: center;
    padding-top: 0.5rem;
  }

  .col-md-6 {
    margin-bottom: 1.5rem;
  }

  h1 {
    margin-bottom: 2rem;
    font-weight: bold;
  }
}

.callout {
  background: #fef035;
  padding: 1rem;
  font-size: 2em;
  font-weight: bold;
  position: relative;
  margin-bottom: 1rem;

  &:hover {
    border-color: black;
  }

  div {
    height: 5px;
    width: 98%;
    background: black;
    position: absolute;
    bottom: -5px;
    left: 1%;
  }
}

.large-text {
  font-size: 1.1em;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.button {
  padding: 0;
  margin: 0;
  background: transparent;
  border: 3px solid black;
  font-size: 1.1em;
  font-weight: 700;
  cursor: pointer;
  height: 52px;
  position: relative;

  &:disabled {
    color: rgba(0, 0, 0, 0.8);
    border: 3px solid rgba(0, 0, 0, 0.8);
  }

  &:before {
    border-bottom: 3px solid #00aeef;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    pointer-events: none;
  }

  &:hover {
    &:before {
      border-color: black;
    }
  }

  span {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    padding-bottom: 9px;
    // padding: 0.7rem;
  }
}

.pink-box {
  margin: 0 auto;
  color: $white;
  background-color: #ec008c;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  i {
    font-size: 2.2em;
  }
}

.ui {
  header {
    display: flex;
    flex: 1;
    align-items: center;
    border-top: 3px solid black;
    border-bottom: 3px solid black;
    border-left: 3px solid black;
    border-right: 3px solid black;
    padding-left: 1rem;
    font-weight: bold;
    font-size: 1.3em;
    height: 55px;
    position: relative;
    background: $white;

    button {
      margin: 0;
      padding: 0;
      display: block;
      position: absolute;
      right: 0;

      width: 55px;
      height: 55px - 6px;
      background: #fed036;
      line-height: 0;

      border: none;
      border-left: 3px solid black;
      cursor: pointer;

      &:disabled {
        color: white;
        cursor: not-allowed;
        background: rgba(0, 0, 0, 0.5);
      }

      i {
        font-size: 2.12em;
      }
    }
  }
}

.cards {
  .select-card {
    display: none;
  }
}

.merging .cards {
  z-index: 10;
  position: relative;
  padding-top: 1rem;

  .thumbs,
  .edit,
  .votes {
    display: none;
  }

  .select-card {
    display: block;
  }

  .card {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;

    &.selected {
      border-color: #ec008c;

      &:hover {
        border-color: darken(#ec008c, 5%);
      }
    }

    &:hover {
      border-color: $blue;
      cursor: pointer;
    }
  }
}

.lists {
  @include make-row;

  .list {
    @include make-col-ready;
    margin-bottom: 1rem;

    @include media-breakpoint-down(sm) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(4);
    }

    &:after {
      content: '';
      height: 4px;
      background: black;
      position: absolute;
      right: 25px;
      left: 25px;
    }

    .cards {
      min-height: 120px;
      background: $white;
      border-left: 3px solid $black;
      border-bottom: 3px solid $black;
      border-right: 3px solid $black;
      // overflow-y: scroll;
      // max-height: 63vh;
    }
  }
}

.subheading {
  width: 100%;
  display: flex;
  border: 3px solid black;
  margin-top: 1rem;
  height: 50px;
  margin-bottom: 1rem;
  background: $white;
  position: relative;

  @include media-breakpoint-down(sm) {
    margin-top: 0.5rem;
    height: 40px;
  }

  div {
    display: flex;

    font-weight: 600;

    &:not(:last-child) {
      // border-right: 3px solid $black;
    }
  }

  .title {
    padding: 0 1rem;
    font-size: 1.2em;
    align-items: center;
    justify-content: center;
    border-right: 3px solid $black;
  }

  .date {
    font-size: 1.2em;
    align-items: center;
    justify-content: center;
    border-right: 3px solid $black;
    padding: 0 1rem;

    i {
      min-width: 40px;
      font-size: 1.5em;
    }
  }

  .sort {
    cursor: pointer;
    padding: 0 1rem;
    font-size: 1.2em;
    align-items: center;
    justify-content: center;
    border-right: 3px solid $black;
    // flex-basis: 180px;

    &:hover {
      background: #f4f4f4;
    }

    i {
      min-width: 35px;
      font-size: 1.4em;
      // padding-right: 0.5rem;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .end {
    flex: 1;
    justify-content: flex-end;
  }

  .people {
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-left: 3px solid $black;

    i {
      padding-right: 0.5rem;
    }
  }

  .votes {
    align-items: center;
    justify-content: center;

    padding: 0 1rem;
    border-left: 3px solid $black;
    border-right: 3px solid $black;
    // div {
    //   border-left: 3px solid $black;
    // }

    i {
      padding-right: 0.5rem;
    }
  }

  .settings,
  .export {
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;

    &:hover {
      background: #f4f4f4;
    }
  }

  .export {
    border-right: 3px solid $black;
  }
}

.heading {
  width: 100%;
  display: flex;
  border: 3px solid black;
  margin-top: 1rem;
  height: 80px;

  @include media-breakpoint-down(xs) {
    height: 50px;

    .retro-name h1 {
      font-size: 1.6em;
    }
  }
}

.heading .menu {
  flex-basis: 80px;
  border-right: 3px solid black;
  margin-right: 1rem;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #f4f4f4;
  }

  i {
    @include media-breakpoint-up(sm) {
      font-size: 2.7em;
    }
  }

  @include media-breakpoint-down(xs) {
    flex-basis: 50px;
    margin-right: 0;

    i {
      font-size: 2em;
    }
  }
}

.heading .timer {
  flex-basis: 148px;
  border-left: 3px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
  cursor: pointer;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.timer i {
  padding-left: 0.5rem;
}

.timer-text {
  min-width: 55px;
}

.heading .settings {
  flex-basis: 80px;
  border-left: 3px solid black;
}

.heading .votes {
  justify-content: center;
  font-size: 1.1em;
  flex-basis: 80px;
  border-left: 3px solid black;
  display: flex;
  align-items: center;
}
.heading .logo {
  flex-basis: 80px;
  border-left: 3px solid black;
  display: flex;
  align-items: center;
  background: #02a8f3;

  @include media-breakpoint-down(sm) {
    flex-basis: 45px;
  }
}
.heading .logo img {
  margin: 0 auto;
  width: 48px;
  height: auto;

  @include media-breakpoint-down(sm) {
    width: 28px;
  }
}
.heading .retro-name {
  display: flex;
  flex: 5;
  align-items: center;
  line-height: 0;
  margin-left: 1.1rem;

  @include media-breakpoint-down(xs) {
    margin-left: 0.5rem;
  }
}
.heading h1 {
  margin: 0;
  font-size: 2.2em;
  font-weight: bold;
}

.little-vote {
  cursor: pointer;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #00aeef;
  margin-right: 5px;
}

.modal {
  position: absolute;
  // top: 20%;
  // left: 20%;
  // right: 20%;
  // bottom: 20%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 18%;
  background-color: $white;
  padding: 2rem;
  border: 3px solid $black;

  .button {
    margin-top: 1rem;
  }
}

.settings-modal {
  width: 700px;

  @include media-breakpoint-down(sm) {
    top: 0;
    width: 100%;
    padding: 1.5rem;
  }
}

.modal:after {
  content: '';
  display: block;
  position: absolute;
  background: $black;
  left: 15px;
  right: 15px;
  height: 5px;
  bottom: -8px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.navbar {
  background: $white;
}

.edit-container {
  background: #f4f4f4;
  padding: 0.3rem 0.5rem;
  padding-bottom: 0.5rem;
}

.form-control {
  border: 3px solid $black;
  border-radius: 0;
  color: $black;

  &:focus {
    box-shadow: none;
    border-color: $blue;
  }
}

.form-group label {
  font-weight: 600;
}

.card .copy-directions {
  display: none;
}

.card.first .copy-directions {
  text-transform: uppercase;
  display: block;
  position: absolute;
  font-weight: bold;
  right: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: $black;
  color: $white;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 5px;
}

.app-image {
  text-align: center;
  @include media-breakpoint-down(sm) {
    margin-bottom: 1rem;
  }
}

.merge-control {
  position: relative;
  z-index: 100;
  background: $white;
}

.screenshots {
  text-align: center;
}

.why {
  text-align: center;

  span {
    font-size: 1.2em;
  }

  h1 {
    text-align: center;
  }
}

.card {
}

.loader {
  &,
  &:before,
  &:after {
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: loader 1.8s infinite ease-in-out;
  }

  color: #02a8f3;
  font-size: 10px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  // transform: translateZ(0);
  animation-delay: -0.16s;

  top: 40%;
  transform: perspective(1px) translateY(-50%);
  z-index: 50000;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }

  &:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }

  &:after {
    left: 3.5em;
  }

  @keyframes loader {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
}

.timer-popup {
  text-align: left;
  z-index: 100;
  font-size: 16px;
  font-weight: 600;
  top: 93px;
  right: 95px;
  // right: 20px;
  position: absolute;
  background: #f4f4f4;
  padding: 1rem;
  border: 3px solid $black;
  width: 151px;

  &:after,
  &:before {
    bottom: 100%;
    left: 80%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #f4f4f4;
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: $black;
    border-width: 14px;
    margin-left: -14px;
  }

  // &:before {
  //   content: '';
  //   position: absolute;
  //   bottom: 100%;
  //   left: 50%;
  //   right: 0;
  //   margin: 0 auto;
  //   width: 0;
  //   height: 0;
  //   border-bottom: solid 25px #f4f4f4;
  //   border-left: solid 25px transparent;
  //   border-right: solid 25px transparent;
  // }

  .border-bottom {
    height: 4px;
    background: black;
    position: absolute;
    right: 5px;
    left: 5px;
    bottom: -5px;
  }

  button {
    cursor: pointer;
    background: transparent;
    border: none;
    font-weight: 600;

    &:hover {
      color: $blue;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

footer {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
